.App {
  text-align: center;
  max-width: 500px;
  margin: auto;
}

.header {
  left: 0;
  right: 0;
  margin: auto;
  margin-bottom: 10px;
  padding: 2px 0;
  color: white;
  background-color: red;
}
.step {
  position: relative;
  display: none;
}
.step.-show {
  display: block;
}

.step1 {
  padding: 20px;
}

.step2 > div {
  margin: auto;
}

.step3 {
  margin: auto;
}
.step3 .badge {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.step3 .badge.badge1 {
  bottom: 60px;
}

.step3 > div {
  margin: auto;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.badge-group {
  margin: 10px;
  display: flex;
}

.badge-group .btn-badge {
  margin-top: 20px;
  padding: 10px;
  width: 33.333%;
}

.btn-crop {
  padding: 20px;
}

.btn-save {
  padding: 20px;
}

@media only screen and (max-width: 500px) {
  .App {
    max-width: 100%;
  }
}